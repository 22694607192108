
































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ListComponentTextArea extends Vue {
@Prop({ type: Array }) public elementList: string[];
@Prop({ type: String }) public label: string;
@Prop({ type: Boolean }) public disabled: string;

    public menu = false;

    get list() {
        return this.elementList;
    }

    get inputLabel() {
        return this.label;
    }

}
