


























































































































































































/**
 * @file BiomarkerSexSpecificForm.vue
 * @Author: Mcastrucci
 * Form template for creating sex specific information.
 * This form should be used in biomarkers forms to include sex specific information.
 */
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import ListComponentTextArea from '@/components/ListComponentTextArea.vue';
import ListComponent from '@/components/ListComponent.vue';

import { dispatchGetBiomarkersForList, dispatchGetRecommendationsForTable } from '@/store/crud/actions';
import { ICreateSexSpecifics, ISexSpecifics, IUpdateSexSpecifics } from '@/interfaces/sexSpecifics';
import { biomarkerSexOptions } from '@/constants/listOptions';
import { IRecommendation } from '@/interfaces/recommendations';
import { ICreateCritical, IUpdateCritical } from '@/interfaces/criticals';

@Component({components: {ListComponentTextArea, ListComponent}})
export default class BiomarkerSexSpecificForm extends AppComponent {
  @Prop({type: String, required: false}) public biomarkerId!: string;
  @Prop({type: Object, required: false}) public biomarkerSexSpecific!: ISexSpecifics;

  public valid = false;
  public biomarkerSexOptions = biomarkerSexOptions;
  public whyItMatters: string = '';
  public oneLineDescription: string = '';
  public selectedBiomarkers: string[] = [];
  public notes: string = '';
  public sex: string = '';
  // biomarker id used for editing, i ncase of creation, the biomarkerId is used as a prop
  public biomarker: string = '';
  public questRefRangeLow: string = '';
  public questRefRangeHigh: string = '';
  public optimalRangeLow: string = '';
  public optimalRangeHigh: string = '';
  public resourcesCited: string[] = [];
  public assignedRecommendations: IRecommendation[] = [];
  public busId: number = 0;

  // criticals
  public isCritical: boolean = false;
  public isPriority1: boolean = false;
  public isPriority2: boolean = false;
  public priority1Range: string = '';
  public priority2Range: string = '';

  public mounted() {
    this.initScreen();
    this.busId = this.$bus.$on('biomarkerSexSpecific:reset', this.initScreen);
  }

  public beforeDestroy() {
    this.$bus.$off('biomarkerSexSpecific:reset', this.busId);
  }

  get isEditing() {
    return this.biomarkerId ? false : true;
  }

  get assignedBiomarker() {
    return this.biomarkerId || this.biomarker;
  }

  /**
   * Watch for changes in the isCritical property.
   * To be UX friendly, if the user unchecks the isCritical property,
   * the priority 1 and 2 properties are also unchecked.
   */
  @Watch('isCritical')
  public onIsCriticalChange() {
    if (!this.isCritical) {
      this.isPriority1 = false;
      this.isPriority2 = false;
      this.priority1Range = '';
      this.priority2Range = '';
    }
  }

  public async initScreen() {
    this.setAppLoading(true);
    await this.fetchData();
    this.reset();
    this.setAppLoading(false);
  }

  public async fetchData() {
    await dispatchGetBiomarkersForList(this.$store);
    await dispatchGetRecommendationsForTable(this.$store);
  }

  /**
   * Event handler for cancel button
   */
  public cancel() {
    this.$emit('biomarkerSexSpecific:cancelled');
  }


  public reset() {
    if (this.biomarkerSexSpecific && Object.keys(this.biomarkerSexSpecific).length > 0) {
      this.biomarker = this.biomarkerSexSpecific.biomarkerId ? this.biomarkerSexSpecific.biomarkerId : '';
      this.sex = this.biomarkerSexSpecific.sex ? this.biomarkerSexSpecific.sex : 'All';
      this.whyItMatters = this.biomarkerSexSpecific.whyItMatters ? this.biomarkerSexSpecific.whyItMatters : '';
      this.oneLineDescription = this.biomarkerSexSpecific.oneLineDescription
        ? this.biomarkerSexSpecific.oneLineDescription : '';
      this.selectedBiomarkers = this.biomarkerSexSpecific.crossReferenceBiomarkers ?? [];
      this.notes = this.biomarkerSexSpecific.notes ? this.biomarkerSexSpecific.notes : '';
      this.questRefRangeLow = this.biomarkerSexSpecific.questRefRangeLow ?? '';
      this.questRefRangeHigh = this.biomarkerSexSpecific.questRefRangeHigh ?? '';
      this.optimalRangeLow = this.biomarkerSexSpecific.optimalRangeLow ?? '';
      this.optimalRangeHigh = this.biomarkerSexSpecific.optimalRangeHigh ?? '';
      this.resourcesCited = this.biomarkerSexSpecific.resourcesCited
        ? this.cloneList(this.biomarkerSexSpecific.resourcesCited) : [];
      this.assignedRecommendations = this.biomarkerSexSpecific.recommendations
        ? this.biomarkerSexSpecific.recommendations : [];
      this.isCritical = this.biomarkerSexSpecific.critical ? this.biomarkerSexSpecific.critical.isPriority1 || false
        || this.biomarkerSexSpecific.critical.isPriority2 || false : false;
      this.isPriority1 = this.biomarkerSexSpecific.critical ?
        this.biomarkerSexSpecific.critical.isPriority1 || false : false;
      this.isPriority2 = this.biomarkerSexSpecific.critical ?
        this.biomarkerSexSpecific.critical.isPriority2 || false : false;
      this.priority1Range = this.biomarkerSexSpecific.critical ?
        this.biomarkerSexSpecific.critical.priority1Range || '' : '';
      this.priority2Range = this.biomarkerSexSpecific.critical ?
        this.biomarkerSexSpecific.critical.priority2Range || '' : '';
    } else {
      this.whyItMatters = '';
      this.oneLineDescription = '';
      this.sex = 'All';
      this.selectedBiomarkers = [];
      this.notes = '';
      this.questRefRangeLow = '';
      this.questRefRangeHigh = '';
      this.isCritical = false;
      this.optimalRangeLow = '';
      this.optimalRangeHigh = '';
      this.resourcesCited = [];
      this.assignedRecommendations = [];
      this.isPriority1 = false;
      this.isPriority2 = false;
      this.priority1Range = '';
      this.priority2Range = '';
    }
    this.$validator.reset();
  }

  public handleDelete() {
    this.$emit('biomarkerSexSpecific:deleted');
  }

  /**
   * Event handler for submit button
   * Validate the form and emit the event to the parent component
   */
  public async submit() {
    if (await this.$validator.validateAll()) {
      // create the biomarker
      const createSexSpecific: ICreateSexSpecifics = {
        biomarkerId: this.biomarkerId,
        sex: this.sex,
      };
      if (!this.isEditing) {
        createSexSpecific.whyItMatters = this.whyItMatters;
        createSexSpecific.oneLineDescription = this.oneLineDescription;
        createSexSpecific.crossReferenceBiomarkers = this.selectedBiomarkers;
        createSexSpecific.notes = this.notes;
        createSexSpecific.resourcesCited = this.resourcesCited;
        createSexSpecific.questRefRangeLow = this.questRefRangeLow ?? '';
        createSexSpecific.questRefRangeHigh = this.questRefRangeHigh ?? '';
        createSexSpecific.optimalRangeLow = this.optimalRangeLow  ?? '';
        createSexSpecific.optimalRangeHigh = this.optimalRangeHigh ?? '';

        if (this.isEmpty(createSexSpecific.sex)) { this.toast('Sex is required'); return; }
        if (this.isEmpty(createSexSpecific.biomarkerId)) { this.toast('Biomarker is required'); return; }
        if (this.isEmpty(createSexSpecific.whyItMatters)) { delete createSexSpecific.whyItMatters; }
        if (this.isEmpty(createSexSpecific.oneLineDescription)) { delete createSexSpecific.oneLineDescription; }
        if (this.isEmpty(createSexSpecific.crossReferenceBiomarkers)) {
          delete createSexSpecific.crossReferenceBiomarkers; }
        if (this.isEmpty(createSexSpecific.notes)) { delete createSexSpecific.notes; }
        if (this.isEmpty(createSexSpecific.resourcesCited)) { delete createSexSpecific.resourcesCited; }
        if (this.isEmpty(createSexSpecific.questRefRangeLow)) { delete createSexSpecific.questRefRangeLow; }
        if (this.isEmpty(createSexSpecific.questRefRangeHigh)) { delete createSexSpecific.questRefRangeHigh; }
        if (this.isEmpty(createSexSpecific.optimalRangeLow)) { delete createSexSpecific.optimalRangeLow; }
        if (this.isEmpty(createSexSpecific.optimalRangeHigh)) { delete createSexSpecific.optimalRangeHigh; }

        // create the critical creation payload
        const createCritical: ICreateCritical = {
          isPriority1: this.isPriority1 || false,
          isPriority2: this.isPriority2 || false,
          priority1Range: this.priority1Range,
          priority2Range: this.priority2Range,
        };

        if (this.isPriority1) {
          if (this.isEmpty(createCritical.priority1Range)) { this.toast('Piority 1 selected, Priority 1 Range is required', true); return; }
        } else {
          delete createCritical.priority1Range;
        }

        if (this.isPriority2) {
          if (this.isEmpty(createCritical.priority2Range)) { this.toast('Priority 2 selected, Priority 2 Range is required', true); return; }
        } else {
          delete createCritical.priority2Range;
        }

        if (this.isCritical && !this.isPriority1 && !this.isPriority2) {
          this.toast('If Biomarker is Critical, at least one priority must be selected', true);
          return;
        }

        // emit creation
        this.$emit('biomarkerSexSpecific:created', createSexSpecific, createCritical);
      } else {
          const updateSexSpecific: IUpdateSexSpecifics = {};
        // edit case
          this.setFieldIfChanges(updateSexSpecific, 'sex', this.sex, this.biomarkerSexSpecific.sex);
          this.setFieldIfChanges(updateSexSpecific, 'whyItMatters',
            this.whyItMatters, this.biomarkerSexSpecific.whyItMatters);
          this.setFieldIfChanges(updateSexSpecific, 'oneLineDescription',
            this.oneLineDescription, this.biomarkerSexSpecific.oneLineDescription);
          this.setFieldIfChanges(updateSexSpecific, 'crossReferenceBiomarkers',
            this.selectedBiomarkers, this.biomarkerSexSpecific?.crossReferenceBiomarkers ?? []);
          this.setFieldIfChanges(updateSexSpecific, 'notes', this.notes, this.biomarkerSexSpecific.notes);
          this.setFieldIfChanges(updateSexSpecific, 'resourcesCited', this.resourcesCited,
            this.biomarkerSexSpecific.resourcesCited ?
            this.biomarkerSexSpecific.resourcesCited : []);
          this.setFieldIfChanges(updateSexSpecific, 'questRefRangeLow', this.questRefRangeLow,
            this.biomarkerSexSpecific.questRefRangeLow ?? '');
          this.setFieldIfChanges(updateSexSpecific, 'questRefRangeHigh', this.questRefRangeHigh,
            this.biomarkerSexSpecific.questRefRangeHigh ?? '');
          this.setFieldIfChanges(updateSexSpecific, 'optimalRangeLow', this.optimalRangeLow,
            this.biomarkerSexSpecific.optimalRangeLow ?? '');
          this.setFieldIfChanges(updateSexSpecific, 'optimalRangeHigh', this.optimalRangeHigh,
            this.biomarkerSexSpecific.optimalRangeHigh ?? '');

          // create the critical update payload
          const updateCritical: IUpdateCritical = {};

          this.setFieldIfChanges(updateCritical, 'isPriority1', this.isPriority1,
            this.biomarkerSexSpecific.critical ? this.biomarkerSexSpecific.critical.isPriority1 : false);
          this.setFieldIfChanges(updateCritical, 'isPriority2', this.isPriority2,
            this.biomarkerSexSpecific.critical ? this.biomarkerSexSpecific.critical.isPriority2 : false);
          this.setFieldIfChanges(updateCritical, 'priority1Range', this.priority1Range,
            this.biomarkerSexSpecific.critical ? this.biomarkerSexSpecific.critical.priority1Range : '');
          this.setFieldIfChanges(updateCritical, 'priority2Range', this.priority2Range,
            this.biomarkerSexSpecific.critical ? this.biomarkerSexSpecific.critical.priority2Range : '');

          if (this.isCritical && !this.isPriority1 && !this.isPriority2) {
            this.toast('If Biomarker is Critical, at least one priority must be selected', true);
            return;
          } else if (this.isCritical && this.isPriority1 && this.isEmpty(this.priority1Range)) {
            this.toast('Piority 1 selected, Priority 1 Range is required', true);
            return;
          } else if (this.isCritical && this.isPriority2 && this.isEmpty(this.priority2Range)) {
            this.toast('Priority 2 selected, Priority 2 Range is required', true);
            return;
          }

          // check if there is at least one field that has changed
          if (Object.keys(updateSexSpecific).length > 0 || Object.keys(updateCritical).length > 0) {
            // emit update
            this.$emit('biomarkerSexSpecific:updated', this.biomarkerSexSpecific.id, updateSexSpecific, updateCritical);
          }
      }
    }
  }
}

