




import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import BiomarkerSexSpecificForm from '@/components/forms/BiomarkerSexSpecificForm.vue';
import { ISexSpecifics, IUpdateSexSpecifics } from '@/interfaces/sexSpecifics';


@Component({ components: { BiomarkerSexSpecificForm } })
export default class BiomarkerSexSpecific extends AppComponent {
  @Prop({ type: Object, required: true }) public biomarkerSexSpecific: ISexSpecifics;

  public cancel() {
    this.$router.push('/main/content/foods');
  }

  public handleDelete() {
    this.$emit('biomarkerSexSpecific:deleted', this.biomarkerSexSpecific.id);
  }

  public handleUpdate(id, updatePayload: IUpdateSexSpecifics, critical) {
    this.$emit('biomarkerSexSpecific:updated', id, updatePayload, critical);
  }

}
