


























































































































import { Component, Prop } from 'vue-property-decorator';
import {
  dispatchCreateBiomarker,
  dispatchCreateCritical,
  dispatchCreateSexSpecific,
  dispatchDeleteSexSpecific,
  dispatchExpireDataByModuleName,
  dispatchGetBiomarkerById,
  dispatchGetBiomarkersForList,
  dispatchGetCategories,
  dispatchUpdateBiomarker,
  dispatchUpdateCritical,
  dispatchUpdateSexSpecific,
} from '@/store/crud/actions';
import ListComponent from '@/components/ListComponent.vue';
import CreateBiomarkerSexSpecific from '@/components/modals/CreateBiomarkerSexSpecificModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { ICreateCritical, IUpdateCritical } from '@/interfaces/criticals';
import { IBiomarker, ICreateBiomarker, IUpdateBiomarker } from '@/interfaces/biomarkers';
import SexSpecific from './SexSpecific.vue';
import { ICreateSexSpecifics, ISexSpecifics, IUpdateSexSpecifics } from '@/interfaces/sexSpecifics';
import { crudModules } from '@/constants/globalConstants';

@Component({ components: { ListComponent, CreateBiomarkerSexSpecific, SexSpecific } })
export default class Biomarker extends AppComponent {
  @Prop({ required: false }) public biomarkerId: string; // the id of the Biomarker we are editing
  public step = '1';
  public biomarker = {} as IBiomarker; // original biomarker
  public isEditing = false;
  public dialog = false;

  public valid = false;

  public name: string = '';
  public questBiomarkerCode: string = '';
  public modeOfAcquisition: string = '';
  public assignedCategories: string[] = [];
  public notes: string = '';
  public measurementUnits: string = '';
  public sexSpecifics: ISexSpecifics[] = [];
  public panel = 0;



  public created() {
    this.initScreen();
  }

  /**
   * async function to init the screen
   * it fetches data from store and configures the form
   */
  public async initScreen() {
    this.setAppLoading(true);

    await this.fetchData();
    this.reset();
    this.setAppLoading(false);
    this.checkNextStep();
  }


  public async fetchData() {
    if (this.biomarkerId) {
      await dispatchGetCategories(this.$store);
      this.biomarker = await dispatchGetBiomarkerById(this.$store, this.biomarkerId);
      if (!this.biomarker) {
        this.toast('Biomarker not found', true);
        this.biomarker = {} as IBiomarker;
      } else {
        this.isEditing = true;
      }
    }
    await dispatchGetBiomarkersForList(this.$store);
  }

  public checkNextStep() {
    if (this.biomarker && Object.keys(this.biomarker).length > 0) {
      if (!this.biomarker.sexDetails || this.biomarker.sexDetails.length === 0) {
        this.step = '2';
      } else {
        this.step = '1';
      }
    }
  }

  /**
   * it resets the form
   */
  public reset() {
    if (this.isEditing) {
      this.name = this.biomarker.name || '';
      this.questBiomarkerCode = this.biomarker.questBiomarkerCode || '';
      this.modeOfAcquisition = this.biomarker.modeOfAcquisition || '';
      this.measurementUnits = this.biomarker.measurementUnits || '';
      this.assignedCategories = this.biomarker.categories
        && this.biomarker.categories.length > 0 ? this.extractIds(this.biomarker.categories) : [];
      this.sexSpecifics = this.biomarker.sexDetails ? this.biomarker.sexDetails : [];

    } else {
      this.name = '';
      this.questBiomarkerCode = '';
      this.modeOfAcquisition = '';
      this.measurementUnits = '';
      this.assignedCategories = [];
      this.sexSpecifics = [];
    }
    this.$validator.reset();
  }

  /**
   * cancel the biomarker creation and goes back to admin biomarkers page
   */
  public cancel() {
    this.$router.back();
  }

  /**
   * Event handler for biomarkerSexSpecific creation.
   * Every biomarkerSexSpecific creation comes with the creation of a critical value
   * Creates a new biomarkerSexSpecific and adds it to the biomarker
   */
  public async sexSpecificCreated(creationPayload: ICreateSexSpecifics, critical: ICreateCritical) {
    if (creationPayload && Object.keys(creationPayload).length > 0) {
      // before creating, the code checks if a sexSpecific for the the current sex already exists
      const sexOfPaylod = creationPayload.sex || 'All'; // check what kind of sexSpecific is being created
      const canBeCreated = this.sexSpecificCanBeCreated(sexOfPaylod);

      if (!canBeCreated) {
        if (creationPayload.sex === 'All') {
          this.toast('A sex specific biomarker data for All can only be created if no other sex specific information already exist', true);
        } else {
          this.toast(`A sexSpecific for ${sexOfPaylod} sex can only be created if there is no specifics for "All" and no other ${sexOfPaylod}`, true);
        }
        return;
      }

      const sexSpecific: ISexSpecifics = await dispatchCreateSexSpecific(this.$store, creationPayload);
      this.sexSpecifics.push(sexSpecific);
      if (sexSpecific) {
        // if sexSpecific was created, then a critical value is created
        critical.sexSpecificId = sexSpecific.id;
        const result = await dispatchCreateCritical(this.$store, critical);
        if (result) {
          sexSpecific.critical = result;
        } else {
          this.toast('Error while creating biomarker criticals', true);
          // prevent the user to re-submit the form without updating the information
          this.reset();
          return;
        }
      } else {
        // FIXME - important log here
      }
    }
  }

  /**
   * Checks if the sexSpecific information can be created
   * based on this logic:
   *
   * Allow a maximum of 2 sexDetail entries per biomarker
   * If “All”, not “male” nor “female”
   * If “male” or “female” not “All”
   * If “male” no more “male”
   * If “female” no more “female”
   * If “all” no more “all”
   */
  public sexSpecificCanBeCreated(sex: string) {
    // if sexSpecifics is empty, it can be created for all cases
    if (this.sexSpecifics.length === 0) {
      return true;
    }

    // if sexSpecifics is not empty, it can be created only by the described logic
    switch (sex) {
      case 'All':
        /*
          All can only be created if length is === 0
          since it can only be one All and there cannot be all if there is a Male or Female entry
          Up to this point, the length is more than 1, so it cannot be created
        */
        return false;
      case 'Male':
        /*
          Male can be created only if there is no All and if there is no another Male
          entry.
        */
        const allOrMaleExist = this.sexSpecifics.find((data) => data.sex === 'Male' || data.sex === 'All');
        return allOrMaleExist && allOrMaleExist !== undefined ? false : true;
      case 'Female':
        /*
          Female can be created only if there is not an "All" entry and there is not another "Female" entry
        */
        const allOrFemaleExist = this.sexSpecifics.find((data) => data.sex === 'Female' || data.sex === 'All');
        return allOrFemaleExist && allOrFemaleExist !== undefined ? false : true;
      default:
        return false;
    }
  }

  /**
   * Event handler for biomarkerSexSpecific update
   * it also updates the critical value if there is changes in the form
   * Updates the biomarkerSexSpecific and replaces it in the biomarker
   */
  public async sexSpecificUpdated(id, updatePayload: IUpdateSexSpecifics, critical: IUpdateCritical) {
    if (updatePayload && Object.keys(updatePayload).length > 0) {
      // dispatch the update
      const sexSpecific = await dispatchUpdateSexSpecific(
        this.$store, {
            id, sexSpecific: updatePayload,
          },
      );
      if (sexSpecific) {
        const index = this.sexSpecifics.findIndex((s) => s.id === id);
        this.sexSpecifics.splice(index, 1, sexSpecific);
      } else {
        // FIXME - important log here
      }
    }
    if (critical && Object.keys(critical).length > 0) {
      // before updating the critical value, check if a critical value exists for the current sexSpecific
      const sexSpecific = this.sexSpecifics.find((s) => s.id === id);
      if (sexSpecific) {
        if (sexSpecific.critical) {
          // if a critical value exists, update it
          const result = await dispatchUpdateCritical(this.$store, {
            id: sexSpecific.critical.id!,
            critical,
          });
          if (result) {
            const index = this.sexSpecifics.findIndex((s) => s.id === id);
            this.sexSpecifics[index].critical = result;
          } else {
            // FIXME - important log here
            return;
          }
        } else {
          // weird case, but if a critical value does not exist, create it
          const result = await dispatchCreateCritical(this.$store, critical as ICreateCritical);
          if (result) {
            const index = this.sexSpecifics.findIndex((s) => s.id === id);

            if (index >= 0) {
              this.sexSpecifics[index].critical = result;
            } else {
              // weird error while updating the critical value
              // better to exit the screen to force the user to update the information
              this.initScreen();
            }
          } else {
            // FIXME - important log here
            return;
          }

        }
      } else {
        // This should not happen, it means that the sexSpecific was not found
        this.toast('Error while updating biomarker criticals', true);
      }
    }
  }

  /**
   * removes a sexSpecific entry from the server and from the biomarker
   */
  public async removeSexSpecific(id: string) {
    // to remove the sex specific it needs to remove the critical
    const result = await dispatchDeleteSexSpecific(this.$store, id);
    if (result) {
      const index = this.sexSpecifics.findIndex((s) => s.id === id);
      this.sexSpecifics.splice(index, 1);
    } else {
      // FIXME - important log here
    }
  }

  /**
   * submits the data to the server
   * It checks if the fields are valid and then submits the data
   */
  public async submit() {
    const protoBiomarker: IBiomarker = {};
    if (await this.$validator.validateAll()) {
      this.dialog = false; // closes the confirm dialog
      // create the biomarker
      if (!this.isEditing) {
        protoBiomarker.name = this.name;
        protoBiomarker.questBiomarkerCode = this.questBiomarkerCode;
        protoBiomarker.modeOfAcquisition = this.modeOfAcquisition;
        protoBiomarker.measurementUnits = this.measurementUnits;
        protoBiomarker.categories = this.assignedCategories;

        if (this.isEmpty(protoBiomarker.name)) { this.toast('Name is required'); return; }
        if (this.isEmpty(protoBiomarker.questBiomarkerCode)) { this.toast('Quest Biomarker Code is required'); return; }
        if (this.isEmpty(protoBiomarker.modeOfAcquisition)) { delete protoBiomarker.modeOfAcquisition; }
        if (this.isEmpty(protoBiomarker.measurementUnits)) { delete protoBiomarker.measurementUnits; }
        if (this.isEmpty(protoBiomarker.categories)) { delete protoBiomarker.categories; }

        const createdBiomarker: ICreateBiomarker = protoBiomarker as ICreateBiomarker;
        const result = await dispatchCreateBiomarker(this.$store, createdBiomarker);

        // if results contains categoryId, the categories data needs to be updated to reflect the new biomarker
        // because of that, the categories data needs to be set as expired
        if (result && result.categoryId) {
          dispatchExpireDataByModuleName(this.$store, crudModules.CATEGORIES);
        }
        if (result) {
          this.$router.push(`/main/content/biomarkers/${result.id}`).catch();
          this.biomarker = result;
          this.isEditing = true; // if the biomarker is created, we are now editing
          this.reset();
          this.checkNextStep();
          return;
        } else {
          return; // toast and output handled by the action
        }
      } else {
        // edit case
        if (this.step === '1') {
          this.setFieldIfChanges(protoBiomarker, 'name', this.name, this.biomarker.name);
          this.setFieldIfChanges(protoBiomarker, 'questBiomarkerCode',
            this.questBiomarkerCode, this.biomarker.questBiomarkerCode);
          this.setFieldIfChanges(protoBiomarker, 'modeOfAcquisition',
            this.modeOfAcquisition, this.biomarker.modeOfAcquisition);

          const originalCategories = this.biomarker.categories && this.biomarker.categories.length > 0 ?
            this.extractIds(this.biomarker.categories) : [];
          this.setFieldIfChanges(protoBiomarker, 'categories', this.assignedCategories, originalCategories);

          this.setFieldIfChanges(protoBiomarker, 'measurementUnits',
            this.measurementUnits, this.biomarker.measurementUnits);

          if (Object.keys(protoBiomarker).length > 0) {
            const updateBiomarker: IUpdateBiomarker = protoBiomarker as IUpdateBiomarker;
            const result = await dispatchUpdateBiomarker(this.$store, {
              biomarkerId: this.biomarkerId,
              biomarker: updateBiomarker,
            });
            if (result) {
              this.biomarker = result;
              // if categoryId changed during the update, the categories data needs to be updated
              // because of that, the categories data needs to be set as expired
              if (updateBiomarker.categories) {
                dispatchExpireDataByModuleName(this.$store, crudModules.CATEGORIES);
              }
              this.reset();
              this.checkNextStep();
            } else {
              return; // toast and output handled by the action
            }
          }
        } else {
          this.toast('Invalid step');
        }
      }
    }
  }
}
