



























/**
 * @Author mcastrucci
 * Create / Edit BiomarkerSexSpecific modal
 * This modal contains the biomarkerSexSpecific form. It should be used when it is needed to
 * create a Biomarker sex specific on the fly
 */
import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import BiomarkerSexSpecificForm from '@/components/forms/BiomarkerSexSpecificForm.vue';

@Component({ components: { BiomarkerSexSpecificForm } })
export default class CreateBiomarkerSexSpecific extends AppComponent {
  @Prop({ type: Boolean, default: false }) public isEdit: boolean;
  @Prop({ type: String, required: false }) public biomarkerId: string;

  public dialog = false;
  public busEventId = 0;
  public id = '';

  public created() {
    if (this.isEdit) {
      /*  only list for edit events if this is an edit modal */
      this.busEventId = this.$bus.$on('biomarkerSexSpecific:edit', this.edit);
    }
  }

  public beforeDestroy() {
    if (this.isEdit) {
      this.$bus.$off('biomarkerSexSpecific:edit', this.busEventId);
    }
  }

  /**
   * Event handler for edition.
   * Sets the id to trigger the form to charge biomarkerSexSpecific data
   * and show the dialog
   */
  public async edit(id: string) {
      this.id = id;
      await this.$nextTick();
      this.$bus.$emit('biomarkerSexSpecific:reset');
      this.dialog = true;
  }

  /**
   * Re-emits a creation event to the parent component
   */
  public async elementCreated(result, critical) {
    this.$emit('biomarkerSexSpecific:created', result, critical);
    this.dialog = false;
    await this.$nextTick();
    this.$bus.$emit('biomarkerSexSpecific:reset');
  }

  /**
   * Re-emits an update event to the parent component
   */
  public elementUpdated(result, critical) {
    this.$emit('biomarkerSexSpecific:updated', result, critical);
    this.dialog = false;
  }

  public get isCreation() {
    return !this.id;
  }

  public cancel() {
    this.dialog = false;
    this.$bus.$emit('biomarkerSexSpecific:reset');
  }

}
